import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import notiPlugin from "./assets/js/noti.js";
import alertPlugin from "./assets/js/alert.js";
import commonJs from "./assets/js/common.js";

import axios from "./axios.js"
import VueCookies from 'vue-cookies'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import naver from 'vue-naver-maps';
import VTooltip from 'v-tooltip'
import VueGtag from 'vue-gtag-next';

const app = createApp(App);

app.config.globalProperties.axios = axios;

// app.use(naver, {
//     clientID: 'or1wv14o37',
//     useGovAPI: false, //공공 클라우드 API 사용 (선택)
//     subModules:'' // 서브모듈 (선택)
//   });

app.use(VueCookies, { expires: '1m'})
app.use(VTooltip)
app.use(store).use(router);
app.use(notiPlugin);
app.use(alertPlugin);
app.use(commonJs);

  app.use(
    VueGtag, {
        property: { id: 'G-H51E5XDE1E' }, // GA4 측정 ID 입력
    }, 
    router
  );  
app.mount('#app');