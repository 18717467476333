import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
export default createStore({
    plugins: [createPersistedState()],
    state: {
        login: 'N',
        name : '',
        token: '',
        email : '',
        phone : '',
        products : [],
    },
    getters: {

    },
    mutations: {
        Login(state, info) {
            state.login = 'Y';
            state.name = info.name;
            state.phone = info.phone;
            state.email = info.email;
            state.token = info.token;
            state.products = [];
        },
        ChangeName(state, info) {
            state.name = info.name;
        },
        ChangePhone(state, info) {
            state.phone = info.phone;
        },
        ChangeProduct(state, info) {
            state.products = info.products;
        },
        ChangeProduct(state) {
            state.products = [];
        },
        Logout(state) {
            state.login = 'N';
            state.name = '';
            state.email = '';
            state.phone = '';
            state.token = '';
            state.products = [];
        },
    },
    actions: {
        LOGIN({ commit }, info) {
            commit('Login', info)
        },
        CHANGENAME({ commit }, info) {
            commit('ChangeName', info)
        },
        CHANGEPHONE({ commit }, info) {
            commit('ChangePhone', info)
        },
        CHANGEPRODUCT({ commit }, info) {
            commit('ChangeProduct', info)
        },
        REMOVEPRODUCT({ commit }) {
            commit('RemoveProduct')
        },
        
        LOGOUT({ commit }) {
            commit('Logout')
        },
    },
    modules: {
    }
})
